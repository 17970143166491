import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/the-role-of-a-realtor-in-finding-your-dream-san-diego-home"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage src="../../images/The Selby Team_Role of Realtor.jpg" alt="blogpost" />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Finding your dream home is an exciting journey filled with possibilities and
                opportunities! Amidst the vast array of options and considerations, the guidance and
                expertise of a seasoned realtor can make all the difference.
              </CommonParagraph>
              <CommonParagraph>
                If you’re asking yourself, “What is the role of a realtor in finding my dream home
                in San Diego?” you’ve come to the right place! Let's explore five key ways a realtor
                helps you navigate the intricacies of Southern California’s real estate market and
                find the perfect place to call home.
              </CommonParagraph>
              <Heading type="h2">In-Depth Market Knowledge</Heading>
              <CommonParagraph>
                A San Diego-based realtor thoroughly understands local market; that understanding
                and any accompanying insights are valuable assets in your search for the perfect
                home. From neighborhood trends and property values to soon-to-hit-the-market
                opportunities, the{" "}
                <BlogLink
                  url="https://selbysellssd.com/how-to-choose-a-real-estate-agent-in-san-diego/"
                  text="right realtor"
                />{" "}
                can expertly guide you toward neighborhoods and homes that align with your
                preferences, lifestyle, and budget.
              </CommonParagraph>
              <CommonParagraph>
                That local real estate knowledge has the added benefit of putting a property on your
                radar early. Regarding real estate, demand is incredibly high at the moment. The
                sooner you act on a property, the higher your chances are of securing it.
              </CommonParagraph>
              <Heading type="h2">Tailored Searches</Heading>
              <CommonParagraph>
                With unparalleled access to comprehensive listing databases and industry resources,
                realtors have the tools and expertise to conduct a targeted property search on your
                behalf. Whether you're seeking a condo close to the beach in{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/downtown-san-diego-homes-for-sale"
                  text="Downtown San Diego"
                />{" "}
                , a historic home in{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/mission-hills-homes-for-sale"
                  text="Mission Hills"
                />{" "}
                , or a family-friendly neighborhood in{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/bankers-hill-homes-for-sale"
                  text="Banker Hills"
                />{" "}
                , working with a realtor will result in a curated selection of potential properties
                that meet your criteria and expectations.
              </CommonParagraph>
              <Heading type="h2">Negotiation Expertise</Heading>
              <CommonParagraph>
                When it comes to securing your dream home in San Diego, effective and practical
                negotiation skills are paramount. Realtors are skilled negotiators who advocate for
                your best interests, often securing the most favorable conditions and terms for your
                purchase. And that expertise doesn't end with the price of a home; you can benefit
                from their negotiating experience in everything from contract terms to
                contingencies.
              </CommonParagraph>
              <Heading type="h2">Time-Saving Processes </Heading>
              <CommonParagraph>
                Navigating the complexities of the home-buying process can be overwhelming,
                especially for{" "}
                <BlogLink
                  url="https://selbysellssd.com/first-time-home-buyer-what-to-know/"
                  text="first-time homebuyers"
                />{" "}
                . Realtors provide invaluable support and guidance every step of the way. You'll
                enjoy streamlined property viewings, inspection coordination, and a paperwork
                completion process that's efficient and straightforward. A realtor's streamlined
                approach alleviates stress and allows you to focus on finding your dream home.
              </CommonParagraph>
              <Heading type="h2">Vetted Referrals</Heading>
              <CommonParagraph>
                An often overlooked benefit of working with a local realtor is their extensive
                network of local professionals and service providers. Experienced realtors have
                trusted lenders, inspectors, and contractors they work with. A referral from your
                realtor can keep the process moving forward while giving you the added peace of mind
                from working with vetted professionals.
              </CommonParagraph>
              <Heading type="h2">Find Your Dream Home With a Selby Realtor</Heading>
              <CommonParagraph>
                From their market knowledge and negotiation expertise to their tailored searches,
                streamlined processes, and professional network, a realtor plays a pivotal role in
                helping secure a house. Ready to find your perfect San Diego home? Turn to The Selby
                Team! Our five-star realtors have your best interests at heart –{" "}
                <ContactSlideoutLink text="let's get you into your dream home" /> .
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
